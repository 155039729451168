import router from "../../router";

export default ({ state }) => {
  localStorage.removeItem("cwc-rsvp-remember-me");
  sessionStorage.clear();
  state.email = "";
  if (router.currentRoute.path != "/") {
    router.push("/");
  }
};
