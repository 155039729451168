import wretch from "wretch";

export default () => {
  return wretch("/api/test-connection")
    .post()
    .res()
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};
