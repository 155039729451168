import handleResponse from "@/assets/handle-response";
import wretch from "wretch";

export default ({ state, commit, dispatch }, { member, $confirm }) => {
  $confirm(
    `Are you sure you want to delete ${member.name}? Generally, it's a better idea to deactivate a member.`
  ).then(response => {
    if (response) {
      return wretch("/api/delete-member")
        .auth(state.email)
        .formData({ id: member.id })
        .post()
        .json()
        .then(handleResponse)
        .then(() => {
          dispatch("getAllMembers");
        })
        .catch(text => {
          commit("setSnack", {
            visible: true,
            color: "error",
            text
          });
        });
    }
  });
};
