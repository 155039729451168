<template>
  <div>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="member.name" label="Name"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="email"
              v-model="member.email"
              label="E-Mail"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-btn text @click="buttonAction">{{ buttonText }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    props: ["title", "buttonText", "member", "type"],
    methods: {
      ...mapActions(["createMember", "updateMember"]),
      buttonAction() {
        if (this.type == "insert") {
          this.createMember(this.member).then(() => {
            this.$emit("close");
          });
        } else {
          this.updateMember(this.member).then(() => {
            this.$emit("close");
          });
        }
      },
    },
  };
</script>