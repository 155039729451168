import handleResponse from "@/assets/handle-response";
import wretch from "wretch";

export default ({ state, commit, dispatch }, payload) => {
  dispatch;
  return wretch("/api/send-notifications")
    .auth(state.email)
    .formData(payload)
    .post()
    .json()
    .then(handleResponse)
    .then(errors => {
      if (errors.length > 0) {
        console.error(errors);
        commit("setSnack", {
          visible: true,
          color: "error",
          text: "Not all notifications were sent."
        });

        return false;
      } else {
        commit("setSnack", {
          visible: true,
          color: "success",
          text: "Notifications sent."
        });

        return true;
      }
    })
    .catch(text => {
      commit("setSnack", {
        visible: true,
        color: "error",
        text
      });
    });
};
