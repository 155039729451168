import handleResponse from "@/assets/handle-response";
import wretch from "wretch";

export default ({ state, commit }) => {
  state, wretch;
  return wretch("/api/get-hosts")
    .auth(state.email)
    .get()
    .json()
    .then(handleResponse)
    .then(hosts => {
      state.hosts = hosts;
    })
    .catch(text => {
      commit("setSnack", {
        visible: true,
        color: "error",
        text
      });
    });
};
