<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="meetings"
      dense
      :items-per-page="-1"
      hide-default-footer
    >
      <template #top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="newMeeting">
            <v-icon> mdi-plus-circle </v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template #[`item.date`]="{ item }">
        {{ item.date }}
        <v-btn
          icon
          @click="
            member = item;
            sendNotifications = true;
          "
          v-if="meeting ? item.date == meeting.date : false"
        >
          <v-icon> mdi-mail </v-icon>
        </v-btn>
      </template>

      <template #[`item.host`]="{ item }">
        {{ item.host.name }}
      </template>

      <template #[`item.tools`]="{ item }" :id="item.id">
        <v-btn icon @click="editMeeting(item)">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>

        <v-btn icon @click="deleteMeeting({ meeting: item, $confirm })">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="meetingForm.visible">
      <meeting-form
        :title="meetingForm.title"
        :button-text="meetingForm.buttonText"
        :type="meetingForm.type"
        :meeting="meetingForm.meeting"
        @close="meetingForm.visible = false"
      ></meeting-form>
    </v-dialog>

    <v-dialog v-model="sendNotifications">
      <send-notifications
        :meeting="meeting"
        @close="sendNotifications = false"
      ></send-notifications>
    </v-dialog>
  </div>
</template>

<script>
import SendNotifications from "@/components/SendNotifications";
import MeetingForm from "@/components/MeetingForm";
import { mapActions, mapState } from "vuex";
export default {
  components: { MeetingForm, SendNotifications },
  data: () => ({
    sendNotifications: false,
    meetingForm: {
      visible: false,
      title: "",
      buttonText: "",
      type: "",
      meeting: {},
    },
    headers: [
      { text: "Date", value: "date" },
      { text: "Start Time", value: "startTime" },
      { text: "End Time", value: "endTime" },
      { text: "Host", value: "host" },
      { text: "Tools", value: "tools" },
    ],
  }),

  computed: {
    ...mapState(["meetings", "meeting"]),
  },

  methods: {
    ...mapActions(["getAllMeetings", "getHosts", "deleteMeeting", "getNextMeeting"]),
    newMeeting() {
      this.meetingForm = {
        visible: true,
        title: "New Meeting",
        buttonText: "Create",
        type: "insert",
        meeting: {
          date: new Date().toISOString().substring(0, 10),
          startTime: "13:00",
          endTime: "16:00",
          hostID: 0,
        },
      };
    },
    editMeeting(meeting) {
      this.meetingForm = {
        visible: true,
        title: "Edit Meeting",
        buttonText: "Update",
        type: "update",
        meeting,
      };
    },
  },

  mounted() {
    this.getNextMeeting();
    this.getAllMeetings();
    this.getHosts();
  },
};
</script>
