<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="members"
      sort-by="name"
      :items-per-page="-1"
      hide-default-footer
      dense
      :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field
            dense
            v-model="search"
            clearable
            clear-icon="mdi-close"
            @keydown.esc="search = ''"
            hint="Search"
            persistent-hint
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn icon @click="newMember">
            <v-icon> mdi-plus-circle </v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template #[`item.active`]="{ item }">
        <v-checkbox
          v-model="item.active"
          @change="updateActive(item)"
        ></v-checkbox>
      </template>

      <template #[`item.tools`]="{ item }">
        <v-btn icon @click="editMember(item)">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>

        <v-btn icon @click="deleteMember({ member: item, $confirm })">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="memberForm.visible">
      <member-form
        :title="memberForm.title"
        :button-text="memberForm.buttonText"
        :member="memberForm.member"
        :type="memberForm.type"
        @close="memberForm.visible = false"
      ></member-form>
    </v-dialog>
  </div>
</template>

<script>
  import MemberForm from "@/components/MemberForm.vue";
  import { mapActions, mapState } from "vuex";
  export default {
    components: { MemberForm },
    data: () => ({
      search: "",
      memberForm: {
        visible: false,
        member: {},
        title: "",
        buttonText: "",
        type: "",
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "E-Mail", value: "email" },
        { text: "Last Sign In", value: "lastSignIn" },
        { text: "Active", value: "active" },
        { text: "Tools", value: "tools" },
      ],
    }),
    computed: {
      ...mapState(["members"]),
    },
    methods: {
      ...mapActions(["getAllMembers", "updateActive", "deleteMember"]),
      newMember() {
        this.memberForm = {
          visible: true,
          title: "New Member",
          buttonText: "Create",
          member: {
            name: "",
            email: "",
          },
          type: "insert",
        };
      },
      editMember(member) {
        this.memberForm = {
          visible: true,
          title: `Edit ${member.name}`,
          buttonText: "Update",
          member,
          type: "update",
        };
      },
    },
    mounted() {
      this.getAllMembers();
    },
  };
</script>