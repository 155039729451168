import handleResponse from "@/assets/handle-response";
import wretch from "wretch";

export default ({ state, commit, dispatch }, { host, $confirm }) => {
  $confirm(`Are you sure you want to delete ${host.name}?`).then(response => {
    if (response) {
      return wretch("/api/delete-host")
        .auth(state.email)
        .formData({ id: host.id })
        .post()
        .json()
        .then(handleResponse)
        .then(() => {
          dispatch("getHosts");
        })
        .catch(text => {
          commit("setSnack", {
            visible: true,
            color: "error",
            text
          });
        });
    }
  });
};
