<template>
  <div>
    <v-card>
      <v-card-title> RSVP Summary </v-card-title>
      <v-card-subtitle>
        <v-simple-table dense>
          <tbody>
            <tr
              v-for="response in meeting.responses"
              :key="`response-${response.id}`"
            >
              <th>
                {{ response.description }}
              </th>
              <td>
                {{ statCount(response.description) }}
              </td>
            </tr>
            <tr>
              <th>Unanswered</th>
              <td>
                {{ statCount("Unanswered") }}
              </td>
            </tr>
            <tr>
              <th>Reading</th>
              <td>
                {{ meeting.readers.length }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('view-details')">View Details</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState(["meeting"]),
    },
    methods: {
      statCount(response) {
        return this.meeting.summary[response] || 0;
      },
    },
  };
</script>