<template>
  <div>
    <v-navigation-drawer permanent app clipped>
      <v-list>
        <v-list-item v-for="route in navItems" :key="route.id" :to="route.path">
          <v-list-item-content>
            <v-list-item-title>
              {{ route.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <router-view></router-view>
  </div>
</template>

<script>
  import uniqid from "uniqid";

  export default {
    computed: {
      navItems() {
        const routes = this.$router.options.routes
          .filter(({ name }) => name == "Admin")
          .shift().children;

        return routes.map(({ path, name }) => {
          return {
            id: uniqid(),
            path,
            name,
          };
        });
      },
    },
  };
</script>