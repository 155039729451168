<template>
  <div>
    <v-card>
      <v-card-title> Welcome, {{ member.name }} </v-card-title>
      <v-card-subtitle>
        <div>{{ meetingDate }} {{ startTime }} - {{ endTime }}</div>
      </v-card-subtitle>
      <v-card-text>
        <v-select
          v-model="rsvp"
          :items="meeting.responses"
          item-text="description"
          item-value="description"
          label="Your RSVP Response"
        ></v-select>
      </v-card-text>
      <v-card-text>
        <v-checkbox v-model="reading" label="Reading?"></v-checkbox>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns";
  import { mapActions, mapState } from "vuex";
  export default {
    computed: {
      ...mapState(["member", "meeting"]),
      rsvp: {
        get() {
          if (!this.meeting) return false;
          const rsvp = this.meeting.rsvps.filter(
            ({ memberID }) => memberID == this.member.id
          );
          return rsvp.length > 0 ? rsvp.shift().response : false;
        },
        set(response) {
          if (response == "No") this.reading = false;
          this.setRsvp({ response, meeting: this.meeting });
        },
      },
      reading: {
        get() {
          return (
            this.meeting.readers.filter(
              ({ memberID }) => memberID == this.member.id
            ).length > 0
          );
        },
        set(response) {
          if (!this.rsvp) this.rsvp = "Yes";
          this.setReading({ response, meeting: this.meeting }).then(
            this.getNextMeeting()
          );
        },
      },
      meetingDate() {
        const date = parseISO(this.meeting.date);
        return format(date, "EEE, MMM d, yyyy");
      },
      startTime() {
        const date = parseISO(`${this.meeting.date} ${this.meeting.startTime}`);
        return format(date, "hh:mm aa");
      },
      endTime() {
        const date = parseISO(`${this.meeting.date} ${this.meeting.endTime}`);
        return format(date, "hh:mm aa");
      },
    },
    methods: {
      ...mapActions(["setRsvp", "setReading", "getNextMeeting"]),
    },
  };
</script>