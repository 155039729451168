import handleResponse from "@/assets/handle-response";
import wretch from "wretch";

export default ({ state, commit }) => {
  state, wretch;
  return wretch("/api/next-meeting")
    .auth(state.email)
    .get()
    .json()
    .then(handleResponse)
    .then((meeting) => {
      state.meeting = meeting;
    })
    .catch((text) => {
      commit("setSnack", {
        visible: true,
        color: "error",
        text,
      });
    });
};
