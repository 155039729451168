<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="7">
          <v-card flat>
            <v-card-title> Meeting Location </v-card-title>
            <v-card-subtitle>
              <div>{{ meeting.host.name }}'s house</div>
              <div>
                {{ meeting.host.location }}
              </div>
            </v-card-subtitle>
          </v-card>
          <div v-html="meeting.host.googleCode"></div>
        </v-col>
        <v-col>
          <v-card flat>
            <v-card-title>Notes</v-card-title>
            <v-card-text v-html="formatMD(meeting.host.notes)"></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: { ...mapState(["meeting"]) },
  methods: {
    formatMD: require("@/assets/format-md"),
  },
};
</script>
