import handleResponse from "@/assets/handle-response";
import wretch from "wretch";

export default ({ state, commit, dispatch }, { meeting, $confirm }) => {
  $confirm(
    `Are you sure you want to delete the meeting on ${meeting.date}`
  ).then(response => {
    if (response) {
      return wretch("/api/delete-meeting")
        .auth(state.email)
        .formData({ meetingID: meeting.id })
        .post()
        .json()
        .then(handleResponse)
        .then(() => {
          dispatch("getAllMeetings");
        })
        .catch(text => {
          commit("setSnack", {
            visible: true,
            color: "error",
            text
          });
        });
    }
  });
};
