<template>
  <div>
    <v-card>
      <v-card-title>
        RSVP Details

        <v-spacer></v-spacer>

        <v-icon @click="$emit('close')">mdi-close-circle</v-icon>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :items-per-page="-1"
          hide-default-footer
          :headers="headers"
          :items="meeting.stats"
        >
          <template #[`item.reading`]="{ item }">
            <v-icon color="success" v-if="item.reading"> mdi-check </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    data: () => ({
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Response",
          value: "response",
        },
        {
          text: "Reading",
          value: "reading",
        },
      ],
    }),
    computed: {
      ...mapState(["meeting"]),
    },
  };
</script>
