<template>
  <div>
    <v-card>
      <v-card-title> Send Notifications </v-card-title>
      <v-card-text>
        <v-text-field v-model="subject" label="Subject"></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-textarea v-model="message" label="Message"></v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-btn text @click="sendMessages">Send</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import { format } from "date-fns";
  import Mustache from "mustache";
  import { mapActions } from "vuex";
  export default {
    props: ["meeting"],
    data: () => ({
      subject: "",
      message: "",
    }),
    methods: {
      ...mapActions(["sendNotifications", "getDefaultMessage"]),
      sendMessages() {
        this.sendNotifications({
          subject: this.subject,
          message: this.message,
        }).then((response) => {
          if (response) {
            this.$emit("close");
          }
        });
      },
    },
    mounted() {
      this.getDefaultMessage().then(({ subject, message }) => {
        console.log(this.meeting);
        const info = {
          host: this.meeting.host.name,
          meetingDate: format(
            new Date(`${this.meeting.date} ${this.meeting.startTime}`),
            "eee, MMM d, yyyy"
          ),
          startTime: format(
            new Date(`${this.meeting.date} ${this.meeting.startTime}`),
            "h:mm aa"
          ),
          endTime: format(
            new Date(`${this.meeting.date} ${this.meeting.endTime}`),
            "h:mm aa"
          ),
          location: this.meeting.host.location,
          notes: this.meeting.host.notes || "No notes provided.",
          googleLink: this.meeting.host.googleLink,
        };

        this.subject = Mustache.render(subject, info);
        this.message = Mustache.render(message.join("\n"), info);
      });
    },
  };
</script>
