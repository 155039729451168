<template>
  <div>
    <v-form v-model="validForm">
      <v-card>
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-date-picker
                v-model="meeting.date"
                label="Date"
              ></v-date-picker>
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <vuetify-time-select
                    v-model="meeting.startTime"
                    label="Start Time"
                  ></vuetify-time-select>
                </v-col>
                <v-col>
                  <vuetify-time-select
                    v-model="meeting.endTime"
                    label="End Time"
                  ></vuetify-time-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    label="Host"
                    v-model="meeting.hostID"
                    :items="hosts"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('close')">Cancel</v-btn>
          <v-btn @click="buttonAction" text>{{ buttonText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
  import VuetifyTimeSelect from "vuetify-time-select";
  import uniqid from "uniqid";
  import { mapActions, mapState } from "vuex";
  export default {
    components: { VuetifyTimeSelect },
    props: ["title", "buttonText", "type", "meeting"],
    data: () => ({
      validForm: false,
    }),
    computed: {
      ...mapState(["hosts"]),
    },
    methods: {
      ...mapActions(["createMeeting", "updateMeeting"]),
      buttonAction() {
        if (this.type == "insert") {
          this.createMeeting({ ...this.meeting, uid: uniqid() }).then(() => {
            this.$emit("close");
          });
        } else {
          this.updateMeeting(this.meeting).then(() => {
            this.$emit("close");
          });
        }
      },
    },
  };
</script>