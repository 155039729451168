<template>
  <div>
    <v-container>
      <v-text-field
        v-model="search"
        label="Search"
        clear-icon="mdi-close"
        clearable
        @keydown.esc="search = ''"
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="members"
        :search="search"
        :items-per-page="-1"
        hide-default-footer
      ></v-data-table>
    </v-container>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    data: () => ({
      search: "",
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "E-Mail",
          value: "email",
        },
      ],
    }),
    computed: {
      ...mapState(["members"]),
    },
    mounted() {
      this.$store.dispatch("getAllMembers");
    },
  };
</script>