import handleResponse from "@/assets/handle-response";
import wretch from "wretch";

export default ({ state, commit }) => {
  wretch("/api/all-members")
    .auth(state.email)
    .get()
    .json()
    .then(handleResponse)
    .then(members => {
      state.members = members.sort((a, b) => (a.name < b.name ? -1 : 1));
    })
    .catch(text => {
      commit("setSnack", {
        visible: true,
        color: "error",
        text
      });
    });
};
