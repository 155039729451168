export default ({ state }, email) => {
  state;
  localStorage.setItem(
    "cwc-rsvp-remember-me",
    JSON.stringify({
      email,
      expires: new Date().getTime() + 1209600000
    })
  );
};
