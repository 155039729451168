<template>
  <v-app>
    <v-app-bar app color="grey darken-2" dark v-if="email != ''" clipped-left>
      <v-btn
        text
        v-if="member.isAdmin && !$route.path.includes('/admin')"
        to="/admin"
        >Admin</v-btn
      >
      <v-btn text v-if="$route.path.includes('/admin')" to="/rsvp">
        Exit
      </v-btn>
      <v-spacer></v-spacer>

      <template v-if="member.isAdmin">
        Last Notification Sent: {{ lastSent }}
      </template>

      <v-spacer></v-spacer>

      <v-btn text to="/members" v-if="$route.path != '/members'">Members</v-btn>
      <v-btn text to="/rsvp" v-else>Home</v-btn>

      <v-btn text @click="signOut"> Sign Out </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar v-model="snack.visible" :color="snack.color">
      {{ snack.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "App",

    data: () => ({
      //
    }),

    computed: {
      ...mapState(["snack", "email", "member", "lastSent"]),
    },

    methods: {
      ...mapActions(["signOut"]),
    },
  };
</script>
