import handleResponse from "@/assets/handle-response";
import wretch from "wretch";

export default ({ state, commit }, { response, meeting }) => {
  return wretch("/api/set-reading")
    .auth(state.email)
    .formData({
      response,
      meetingID: meeting.id,
      memberID: state.member.id
    })
    .post()
    .json()
    .then(handleResponse)
    .then(() => {
      commit("setSnack", {
        visible: true,
        color: "success",
        text: "Reading Status Updated"
      });
    })
    .catch(text => {
      commit("setSnack", {
        visible: true,
        color: "error",
        text
      });
    });
};
