import handleResponse from "@/assets/handle-response";
import wretch from "wretch";

export default ({ state, commit, dispatch }, meeting) => {
  return wretch("/api/create-meeting")
    .auth(state.email)
    .formData(meeting)
    .post()
    .json()
    .then(handleResponse)
    .then(() => {
      dispatch("getAllMeetings");
    })
    .catch(text => {
      commit("setSnack", {
        visible: true,
        color: "error",
        text
      });
    });
};
