<template>
  <div>
    <v-container v-if="meeting">
      <v-row>
        <v-col cols="12" md="6">
          <rsvp-card></rsvp-card>
        </v-col>
        <v-col cols="12" md="6">
          <summary-card @view-details="showDetailCard = true"></summary-card>
        </v-col>
        <v-col cols="12">
          <location-card></location-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!meeting && loaded">
      <h1>No meeting currently set.</h1>
      <div>
        You should receive an email when a new meeting is posted, but you can
        check back here whenever you like.
      </div>
    </v-container>

    <v-dialog v-model="showDetailCard">
      <detail-card @close="showDetailCard = false"></detail-card>
    </v-dialog>
  </div>
</template>

<script>
  import RsvpCard from "@/components/RsvpCard.vue";
  import { mapActions, mapState } from "vuex";
  import LocationCard from "@/components/LocationCard.vue";
  import SummaryCard from "@/components/SummaryCard";
  import DetailCard from "@/components/DetailCard";
  export default {
    components: { RsvpCard, LocationCard, SummaryCard, DetailCard },
    data: () => ({
      loaded: false,
      showDetailCard: false,
      response: null,
    }),
    computed: {
      ...mapState(["member", "meeting"]),
    },
    methods: {
      ...mapActions(["getNextMeeting"]),
    },
    mounted() {
      this.getNextMeeting().then(() => {
        this.loaded = true;
      });
    },
  };
</script>