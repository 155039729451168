import wretch from "wretch";
import router from "../../router";
import handleResponse from "@/assets/handle-response";

export default ({ state, commit, dispatch }, { email, rememberMe }) => {
  if (rememberMe) dispatch("setRemember", email);
  wretch("/api/verify-user")
    .auth(email)
    .formData({ signIn: true })
    .post()
    .json()
    .then(handleResponse)
    .then(() => {
      sessionStorage.setItem("cwc-email", email);
      state.email = email;
      router.push("/rsvp");
    })
    .catch(text => {
      commit("setSnack", {
        visible: true,
        color: "error",
        text
      });
    });
};
