import handleResponse from "@/assets/handle-response";
import wretch from "wretch";

export default ({ state, commit, dispatch }, member) => {
  member, dispatch;
  wretch("/api/update-active")
    .auth(state.email)
    .formData({ id: member.id, active: member.active ? 1 : 0 })
    .post()
    .json()
    .then(handleResponse)
    .then(() => {
      dispatch("getAllMembers");
    })
    .catch(text => {
      commit("setSnack", {
        visible: true,
        color: "error",
        text
      });
    });
};
