<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="hosts"
      sort-by="name"
      :items-per-page="-1"
      hide-default-footer
      show-expand
      dense
      :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field
            dense
            v-model="search"
            clearable
            clear-icon="mdi-close"
            @keydown.esc="search = ''"
            hint="Search"
            persistent-hint
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-btn icon @click="newHost">
            <v-icon> mdi-plus-circle </v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-card tile outlined class="my-2">
            <v-card-title> Notes </v-card-title>
            <v-card-text v-html="formatMD(item.notes)"></v-card-text>
          </v-card>

          <div v-html="item.googleCode"></div>
        </td>
      </template>

      <template #[`item.tools`]="{ item }">
        <v-btn icon @click="editHost(item)">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>

        <v-btn icon @click="deleteHost({ host: item, $confirm })">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="hostForm.visible">
      <host-form
        :title="hostForm.title"
        :button-text="hostForm.buttonText"
        :host="hostForm.host"
        :type="hostForm.type"
        @close="hostForm.visible = false"
      ></host-form>
    </v-dialog>
  </div>
</template>

<script>
  import HostForm from "@/components/HostForm.vue";
  import { mapActions, mapState } from "vuex";
  export default {
    components: { HostForm },
    data: () => ({
      search: "",
      hostForm: {
        visible: false,
        host: {},
        title: "",
        buttonText: "",
        type: "",
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Location", value: "location" },
        { text: "Tools", value: "tools" },
      ],
    }),
    computed: {
      ...mapState(["hosts"]),
    },
    methods: {
      ...mapActions(["getHosts", "deleteHost"]),
      formatMD: require("@/assets/format-md"),
      newHost() {
        this.hostForm = {
          visible: true,
          title: "New Host",
          buttonText: "Create",
          host: {
            name: "",
            location: "",
            googleCode: "",
            notes: "",
            attachments: [],
          },
          type: "insert",
        };
      },
      editHost(host) {
        this.hostForm = {
          visible: true,
          title: `Edit ${host.name}`,
          buttonText: "Update",
          host,
          type: "update",
        };
      },
    },
    mounted() {
      this.getHosts();
    },
  };
</script>