<template>
  <div>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="host.name" label="Name"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="host.location"
              label="Location"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea label="Notes" v-model="host.notes"></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="host.googleCode"
              label="Google Code"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-btn text @click="buttonAction">{{ buttonText }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    props: ["title", "buttonText", "host", "type"],
    methods: {
      ...mapActions(["createHost", "updateHost"]),
      buttonAction() {
        if (this.type == "insert") {
          this.createHost(this.host).then(() => {
            this.$emit("close");
          });
        } else {
          this.updateHost(this.host).then(() => {
            this.$emit("close");
          });
        }
      },
    },
  };
</script>