import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Admin from "@/views/Admin";
import Rsvp from "@/views/Rsvp";
import AdminHosts from "@/views/AdminHosts";
import AdminMeetings from "@/views/AdminMeetings";
import AdminMembers from "@/views/AdminMembers";
import Members from "@/views/Members";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/members",
    name: "Members",
    component: Members,
    meta: { requireAuth: true }
  },
  {
    path: "/rsvp/:memberID?",
    name: "RSVP",
    component: Rsvp,
    meta: { requireAuth: true }
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: { requireAuth: true, requireAdmin: true },
    children: [
      {
        path: "/admin/meetings",
        alias: "/admin",
        name: "Manage Meetings",
        component: AdminMeetings
      },
      {
        path: "/admin/members",
        name: "Manage Members",
        component: AdminMembers
      },
      {
        path: "/admin/hosts",
        name: "Manage Hosts",
        component: AdminHosts
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

import store from "../store";
import wretch from "wretch";
import handleResponse from "@/assets/handle-response";

router.beforeEach((to, from, next) => {
  const email = store.state.email;

  if (to.meta.requireAuth) {
    if (email == "" && !to.params.memberID) {
      next("/");
      return;
    } else {
      wretch("/api/verify-user")
        .auth(to.params.memberID || email)
        .post()
        .json()
        .then(handleResponse)
        .then(({ member, lastSent }) => {
          store.commit("setMember", member);
          store.commit("setLastSent", lastSent);
          next();
        })
        .catch(text => {
          store.commit("setSnack", {
            visible: true,
            color: "error",
            text
          });
          next("/");
        });
    }
  } else {
    next();
  }
});

export default router;
