<template>
  <div v-if="showLogin">
    <v-img src="@/assets/background.png" height="96vh" position="bottom" dark>
      <div class="text-h2 text-center mt-5">Cuenca Writers Collective</div>
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-card light>
              <v-card-title> Critique Group RSVP </v-card-title>
              <v-card-text>
                <v-text-field
                  type="email"
                  autocomplete="email"
                  v-model="email"
                  label="Enter your e-mail"
                  hint="Use the same one where you received the invitation"
                  @keydown.enter="signIn({ email, rememberMe })"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <span class="mr-3">Remember Me</span>
                <v-checkbox v-model="rememberMe"></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn text @click="signIn({ email, rememberMe })">Sign In</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="connected === false" justify="center">
          <v-col cols="8">
            <v-card color="warning lighten-2" light>
              <v-card-title class="red--text text--darken-2"> Note </v-card-title>
              <v-card-text>
                <p>
                  There seems to be some sort of problem with your connection to the
                  server behind the scenes.
                </p>

                <p>
                  Trying to connect while using VPN can cause this problem. If you're
                  running VPN, try disabling it and refreshing this page.
                </p>

                <p>
                  If that still doesn't work, then send me an
                  <a href="mailto:cuenca-writers-collective-rsvp@castoware.com">email</a>.
                </p>
                
                <p>
                If you just want to submit your RSVP, you can click the appropriate link
                below:
                <ul>
                  <li>
                    <a
                      href="mailto:cuenca-writers-collective-rsvp@castoware.com?subject=RSVP&body=yes"
                      >Yes, I'm attending and not reading</a
                    >
                  </li>
                  <li>
                    <a
                      href="mailto:cuenca-writers-collective-rsvp@castoware.com?subject=RSVP&body=yes reading"
                      >Yes, I'm attending and reading</a
                    >
                  </li>
                  <li>
                    <a
                      href="mailto:cuenca-writers-collective-rsvp@castoware.com?subject=RSVP&body=no"
                      >No, I'm not attending</a
                    >
                  </li>
                  <li>
                    <a
                      href="mailto:cuenca-writers-collective-rsvp@castoware.com?subject=RSVP&body=tentative"
                      >I might attend</a
                    >
                  </li>
                  <li>
                    <a
                      href="mailto:cuenca-writers-collective-rsvp@castoware.com?subject=RSVP&body=tentative reading"
                      >I might attend and, if I do, I plan to read
                    </a>
                  </li>
                </ul>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
    <v-footer class="caption" color="#190402" dark>
      <v-spacer></v-spacer>
      Image by &nbsp;
      <a
        class="white--text"
        target="_blank"
        href="https://pixabay.com/users/darkmoon_art-1664300/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3224765"
      >
        Darkmoon_Art
      </a>
      &nbsp; from &nbsp;
      <a
        class="white--text"
        target="_blank"
        href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3224765"
      >
        Pixabay
      </a>
      <v-spacer></v-spacer>
    </v-footer>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data: () => ({
    showLogin: false,
    email: "",
    rememberMe: false,
    connected: null,
  }),

  methods: {
    ...mapActions(["checkConnection", "signIn", "setRemember"]),
    ...mapMutations(["setSnack"]),
  },

  mounted() {
    this.checkConnection().then((status) => (this.connected = status));

    const remember = JSON.parse(localStorage.getItem("cwc-rsvp-remember-me")) || {
      email: "",
      expires: new Date() - 100,
    };

    if (new Date().getTime() <= remember.expires) {
      this.setRemember(remember.email);
      this.signIn({ email: remember.email, rememberMe: this.rememberMe });
    } else {
      this.showLogin = true;
      localStorage.removeItem("cwc-rsvp-remember-me");
      this.rememberMe = false;
    }
  },
};
</script>
