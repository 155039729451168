import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import signOut from "./actions/sign-out";
import signIn from "./actions/sign-in";
import getNextMeeting from "./actions/get-next-meeting";
import setRsvp from "./actions/set-rsvp";
import getAllMeetings from "./actions/get-all-meetings";
import getHosts from "./actions/get-hosts";
import createMeeting from "./actions/create-meeting";
import deleteMeeting from "./actions/delete-meeting";
import updateMeeting from "./actions/update-meeting";
import sendNotifications from "@/store/actions/send-notifications";
import getAllMembers from "./actions/get-all-members";
import updateActive from "./actions/update-active";
import deleteMember from "./actions/delete-member";
import createMember from "./actions/create-member";
import updateMember from "./actions/update-member";
import deleteHost from "./actions/delete-host";
import createHost from "./actions/create-host";
import updateHost from "./actions/update-host";
import getDefaultMessage from "./actions/get-default-message";
import setReading from "./actions/set-reading";
import setRemember from "./actions/set-remember";
import checkConnection from "./actions/check-connection";

export default new Vuex.Store({
  state: {
    email: sessionStorage.getItem("cwc-email") || "",
    snack: { visible: false, color: "", text: "" },
    member: false,
    meeting: false,
    adminPasscode: false,
    meetings: [],
    hosts: [],
    members: [],
    lastSent: null,
  },
  mutations: {
    setSnack(state, snack) {
      state.snack = snack;
    },
    setMember(state, member) {
      state.member = member;
    },
    setLastSent(state, lastSent) {
      state.lastSent = lastSent.date.substring(0, 10);
    },
  },
  actions: {
    signOut,
    signIn,
    getNextMeeting,
    setRsvp,
    getAllMeetings,
    getHosts,
    createMeeting,
    deleteMeeting,
    updateMeeting,
    sendNotifications,
    getAllMembers,
    updateActive,
    deleteMember,
    createMember,
    updateMember,
    deleteHost,
    createHost,
    updateHost,
    getDefaultMessage,
    setReading,
    setRemember,
    checkConnection,
  },
  modules: {},
});
