import wretch from "wretch";

export default ({ commit }) => {
  return wretch("/api/get-default-message")
    .get()
    .json()
    .catch((text) => {
      commit("setSnack", {
        visible: true,
        color: "error",
        text,
      });
    });
};
