var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.meetings,"dense":"","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.newMeeting}},[_c('v-icon',[_vm._v(" mdi-plus-circle ")])],1)],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date)+" "),(_vm.meeting ? item.date == _vm.meeting.date : false)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.member = item;
          _vm.sendNotifications = true;}}},[_c('v-icon',[_vm._v(" mdi-mail ")])],1):_vm._e()]}},{key:"item.host",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.host.name)+" ")]}},{key:"item.tools",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editMeeting(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteMeeting({ meeting: item, $confirm: _vm.$confirm })}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)}),_c('v-dialog',{model:{value:(_vm.meetingForm.visible),callback:function ($$v) {_vm.$set(_vm.meetingForm, "visible", $$v)},expression:"meetingForm.visible"}},[_c('meeting-form',{attrs:{"title":_vm.meetingForm.title,"button-text":_vm.meetingForm.buttonText,"type":_vm.meetingForm.type,"meeting":_vm.meetingForm.meeting},on:{"close":function($event){_vm.meetingForm.visible = false}}})],1),_c('v-dialog',{model:{value:(_vm.sendNotifications),callback:function ($$v) {_vm.sendNotifications=$$v},expression:"sendNotifications"}},[_c('send-notifications',{attrs:{"meeting":_vm.meeting},on:{"close":function($event){_vm.sendNotifications = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }